import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { REPORT_MSGS, reportType, reportUrlType } from 'src/app/constants/constants';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CompanyTypeMock } from '../../mock/mock';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';
import { PartDepartmentAnalysisReport, ServiceDepartmentAnalysisReport } from '../reportConstants';

@Component({
  selector: 'app-part-and-service-department-analysis',
  templateUrl: './parts-and-service-department-analysis.component.html',
  styleUrls: ['./parts-and-service-department-analysis.component.scss']
})
export class PartsAndServiceDepartmentAnalysisComponent implements OnInit {

  public currentTabIndex: number = 0;
  public selectedFilter = [];
  public subscription: Subscription = new Subscription();
  public selectedTabIndex = [0];
  public partsAndServiceDepartmentAnalysisjsonObj;
  public sources: Observable<any>[] = [];
  public obj = Object.values;
  public selectedYear;
  selectedDealerCode: string;
  public reportType: string;
   public noDataToDisplayMsg: string;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();

    this.reportType = reportUrlType[reportTypeNameFromUrl];
    this.getPartsAndServiceDepartmentAnalysisReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
  }


  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const dateObj = getMonthAndYear(obj['monthYear']);
    const viewId = this.getViewId(obj['company']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      dealer_no: obj['dealer'].MEMBER_CODE,
      view_id: viewId
    }
    this.sources = [this.customReportsApiService.getPartsAndServiceDepartmentAnalysisData(this.reportType, requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]
    return this.sources;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (filteredValues) => {
          if (filteredValues?.length > 0) {
            this.spinnerService.displaySpinner();
            this.selectedFilter = filteredValues;
            this.mapResponse();
          }

        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
  }

  public getPartsAndServiceDepartmentAnalysisReport() {
    this.partsAndServiceDepartmentAnalysisjsonObj = {
      multiDataSources: true,
      datasource: []

    }
  }

  public async mapResponse() {
    let currentTableResponse = [];
    let tableSubHeaderColumns = [];
    await this.getTabDataSource();
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200) {
          if (resp[0]?.body[0] && resp[0]?.body[0]?.reportHeaderData) {
            tableSubHeaderColumns = this.getTableSubHeaderColumns(resp[0]?.body[0]?.reportHeaderData);
          }
          let reportData = resp[0]?.body[0]?.reportData;
          const header = resp[0]?.body[0]?.reportHeaderData[0].DEALER_CODE;
          let partDepReportHeader = { YTDHeader: [{ [header]: '23' }] };
          if (reportData?.length > 0) {
            reportData.forEach((ele, index) => {
              if (ele.sectionData?.length > 0) {
                const obj = [{
                  "datasourceName": `PartDepartmentDataSource ${index}`,
                  "tableData": ele.sectionData,
                  "sectionTitle": ele.SECTIONTITLE,
                  "sectionId": ele.SECTIONID
                }];
                if (index === 0) {
                  obj[0]['tableHeader'] = this.reportType === reportType.PARTS_DEPARTMENT ? partDepReportHeader.YTDHeader : ServiceDepartmentAnalysisReport.YTDHeader;
                  obj[0]['tableSubHeader'] = tableSubHeaderColumns;
                }
                currentTableResponse.push(obj);
              }

            });
          }
          else{
            this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
          }
        }

        // if (currentTableResponse.length !== 0) {
          this.partsAndServiceDepartmentAnalysisjsonObj.datasource = currentTableResponse;
        // }
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public getTableSubHeaderColumns(tableSubHeader) {
    return [{
      " ": '1',
      [tableSubHeader[0]?.DEALER_NAME]: this.reportType === reportType.PARTS_DEPARTMENT ? '6' : '7',
      [tableSubHeader[0]?.DISTRICT_NAME]: '5',
      [tableSubHeader[0]?.REGION_NAME]: '5',
      [tableSubHeader[0]?.NATIONAL]: '5'
    }]
  }

  public getViewId(company) {
    const view_id = company?.name.toLowerCase();
    if (this.reportType === reportType.PARTS_DEPARTMENT) {
      return `parts_department_${view_id}`
    } else {
      return `service_dept_exp_sales_${view_id}`
    }
  }

}



