<div class="lexus_sales_at_glance">
  <mat-tab-group (focusChange)="tabChange($event.index)">
    <div *ngIf="lexusSalesAtGlancejsonObj?.tabList">
      <mat-tab *ngFor="let tab of lexusSalesAtGlancejsonObj?.tabList">
        <ng-template mat-tab-label>{{ tab.tabName }}</ng-template>
        <div *ngIf="lexusSalesAtGlancejsonObj.tabList[currentTabIndex]?.datasource">
          <div
            *ngFor="let currDataSource of lexusSalesAtGlancejsonObj.tabList[currentTabIndex]?.datasource; let  k = index">
            <app-multi-header-table [tableIndex]="k" *ngIf="!isProfileTable && currentTabIndex !== 5"
              [multiHeaderTableData]="currDataSource" [reportType]="reportType"></app-multi-header-table>
            <div #pdfElement *ngIf="isProfileTable" class="dxfw-content-table-container">
              <app-generic-profile-table [genericProfileTableData]="currDataSource" [isTcuv]="false">
              </app-generic-profile-table>
            </div>
          </div>
        </div>
        <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>