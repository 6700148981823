<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header [collapsedHeight]="'30px'" [expandedHeight]="'30px'">
    <mat-panel-title class="left-content">{{dropdownTitle}}</mat-panel-title>
    <mat-panel-title class="right-content" (click)="onClear();$event.stopPropagation()">CLEAR</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field appearance="fill" class="interval">
    <mat-select #select matNativeControl [(value)]="defaultSelected" (selectionChange)="setSelectedValue($event)"
      placeholder="Select">
      <mat-option *ngFor="let interval of dropdownData" [value]="interval">
        {{interval?.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error *ngIf="requiredMsg && defaultSelected ===''">{{requiredMsg}}</mat-error>
  <mat-error *ngIf="!requiredMsg && noDataValidationMessage && dropdownData.length === 0">{{noDataValidationMessage}}</mat-error>

</mat-expansion-panel>