import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { CRGridSearch } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';

@Component({
  selector: 'app-multi-select-list',
  templateUrl: './multi-select-list.component.html',
  styleUrls: ['./multi-select-list.component.scss']
})
export class MultiSelectListComponent implements OnInit, OnDestroy {

  @ViewChild('select_1', { static: false }) select_1: MatSelect;
  subscription: Subscription = new Subscription();
  public searchTerm = '';
  public selectedRows: any[] = [];
  public filteredRows = [];
  // public isHeaderReq = false;
  public headerLabel = '';
  keyValuePair: any = [];
  searchedRowValue: any;

  @Input() requiredMsg;
  @Output() updateDealerSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() updateSelectedDealers: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter<string>();
  isDisabledMultiSelect: boolean = true;
  @Input() multiSelectFilterTitle;
  reportType: string;
  @Input() isDropDownDisbaled;
  @Input() multiSelectDropdownType;
  @Input() columns;
  @Input() headers;
  @Input() chipFieldToShow;
  @Input() noDataValidationMessage;
  private _dlrHrcyMembersResp: any;

  page = 1;
  size = 10; // Number of items to load per request
  loading = false;
  paginatedRows = [];
  searchTermInput: FormControl;

  get listData() {
    return this._dlrHrcyMembersResp;
  }
  @Input() set listData(value: any) {
    if (this.selectedRows?.length > 0) {
      this.clearSelectedRows();
      this.updateSelectedDealersForFilter();
    }
    this.clearSelectedRows();
    this._dlrHrcyMembersResp = value;
    this.paginatedRows = [];
    this.page = 1;
    this.filteredRows = [...this.listData];
    this.openChanged();
  }

  @Input() set removedFilterKey(value: any) {
    if (value?.key === this.multiSelectDropdownType) {
      const index = this.selectedRows.findIndex(x => x?.MEMBER_CODE == value?.value?.MEMBER_CODE);
      if (index !== -1) {
        this.selectedRows.splice(index, 1);
        this.keyValuePair.splice(index, 1);
        if (this.selectedRows?.length > 0) {
          this.updateSelectedDealersForFilter();
        } else {
          this.clearSelectedRows();
        }
      } else {
        this.clearSelectedRows();
      }
    } else if (value?.key === 'company') {
      this.filteredRows = [];
      this.clearSelectedRows();
    }
  }
  constructor(
  ) {
    this.searchTermInput = new FormControl('');
  }

  ngOnInit(): void {
    this.searchTermInput.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(x => this.searchRows(x));
  }

  // this allows us to enter space charater in seach input
  onKeyUp(event: KeyboardEvent) {
    event.stopPropagation();
  }

  change(event) {
    if (event.isUserInput) {
      let element = event.source.value;
      if (event.source.selected) {
        this.keyValuePair.push({ value: element });
        this.selectedRows.push(element);

      } else if (!event.source.selected) {
        let index = this.keyValuePair.findIndex(x => x.value == element);
        this.keyValuePair.splice(index, 1);
        this.selectedRows.splice(index, 1);
      }
      this.updateSelectedDealersForFilter()
    }
  }

  updateSelectedDealersForFilter() {
    this.selectedRows = [...this.selectedRows];
    this.keyValuePair = [...this.keyValuePair];
    this.updateSelectedDealers.emit(this.keyValuePair);
    const searchChips = { key: this.multiSelectDropdownType, value: this.keyValuePair[0]?.value };
    this.updateDealerSearch.emit(searchChips);
  }


  removeSelection(index: number): void {
    this.selectedRows.splice(index, 1);
    this.keyValuePair.splice(index, 1);
    this.updateSelectedDealersForFilter();

  }

  getSelectedNames(): string {
    return this.selectedRows.map(row => row[this.chipFieldToShow]).join(', ');
  }

  searchRows(searchTerm: string) {
    this.searchedRowValue = [];
    if (searchTerm === '') {
      this.paginatedRows = [];
      this.page = 1;
      this.openChanged();
    } else {
      const filterValue = searchTerm.toLowerCase();
      this.listData.filter(row => {
        Object.values(this.columns).forEach((col: string) => {
          if ((row[col] !== null && row[col] !== undefined) && row[col].toLowerCase()?.includes(filterValue)) {
            if (!this.searchedRowValue.includes(row)) {
              this.searchedRowValue.push(row)
            }
          }
        })
      });
      this.paginatedRows = _.clone(this.searchedRowValue);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClear() {
    this.clearSelectedRows();
    this.clearFilter.emit("dealer")
  }
  clearSelectedRows() {
    this.selectedRows = [];
    this.keyValuePair = [];
  }

  closeMatSelect(select: MatSelect, event) {
    // this.searchTerm = '';
    select.close();
  }

  openChanged() {
    const startIndex = (this.page - 1) * this.size;
    const endIndex = startIndex + this.size;
     this.paginatedRows = _.clone(this.filteredRows.slice(0, endIndex));
    this.page++;
  }
  onScrollChange(event: any, select: string) {
     if (event) {
      this[select].panel.nativeElement.addEventListener(
        'scroll',
        (event: any) => {
          if (event.target.offsetHeight + Math.ceil(event.target.scrollTop) >= event.target.scrollHeight) {

            this.openChanged();
          }
        }
      );
    }
  }

}

