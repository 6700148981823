<div *ngIf="partsAndServiceDepartmentAnalysisjsonObj?.datasource">
  <div
    [ngClass]="partsAndServiceDepartmentAnalysisjsonObj?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
    <div *ngFor="let currdataSource of partsAndServiceDepartmentAnalysisjsonObj?.datasource; let k=index">
      <div class="dfxw-part-table" *ngFor="let datasource of currdataSource ; let  i = index ">
        <app-multi-header-table [tableIndex]="k" [multiHeaderTableData]='datasource' [reportType]="reportType">
        </app-multi-header-table>
      </div>
    </div>
  </div>
  <div class="dxfw-no-data" *ngIf="partsAndServiceDepartmentAnalysisjsonObj?.datasource?.length === 0">
    {{noDataToDisplayMsg}}</div>
  <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div>

</div>