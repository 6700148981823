<aside [class.expanded]="expandedSideNav">
  <h4 (click)="handleSideNavToggle()">{{title}}</h4>
  <div class="toggle top" (click)="handleSideNavToggle()">
    <em class="tmi " [class.tmi-arrow-double-left]="expandedSideNav"
      [class.tmi-arrow-double-right]="!expandedSideNav"></em>
  </div>
 <div [ngStyle]="{'display': expandedSideNav ? '' : 'none' }">
  <ng-container >
    <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
 
    <app-drop-down-list (updateCompanySearch)="updateSearchInfo($event)" *ngIf="requiredFilters?.companyDropdownFilter"
      [requiredMsg]="requiredMsg" [dropdownType]="'company'" [dropdownData]="companyDropdownData"
      [dropdownTitle]="'Company:*'"  [noDataValidationMessage]="noDataMessage" (clearFilter)="clearFilterKey($event)" [removedFilterKey]="removedFilterKey">
    </app-drop-down-list>
 
    <app-filter-date-range *ngIf="requiredFilters?.dateFilter" [reportType]="reportType"
      (updateDateSearch)="updateSearchInfo($event)" [dateFilterData]="dateFilterResp"
      (clearFilter)="clearFilterKey($event)" [noDataValidationMessage]="noDataMessage" [requiredMsg]="requiredMsg"  [removedFilterKey]="removedFilterKey">
    </app-filter-date-range>
 
    <app-drop-down-list *ngIf="requiredFilters?.hierarchyDropdownFilter" [dropdownData]="hierarchyDropdownData"
      (updateCompanySearch)="updateSearchInfo($event)" [dropdownType]="'hierarchy'"
      [dropdownTitle]="'HIERARCHY:*'" [requiredMsg]="requiredMsg" [noDataValidationMessage]="noDataMessage" (clearFilter)="clearFilterKey($event)" [removedFilterKey]="removedFilterKey" >
    </app-drop-down-list>
 
    <app-multi-select-list *ngIf="requiredFilters?.multiSelectDealerMemberFilter"
      (updateDealerSearch)="updateSearchInfo($event)" (updateSelectedDealers)="updateFilterSeletedDealers($event)"
      (clearFilter)="clearFilterKey($event)" [multiSelectFilterTitle]="'DEALER:*'" [listData]="hierarchyResp"
      [multiSelectDropdownType]="'dealer'" [isDropDownDisbaled]="isMultiSelectDisabled" [noDataValidationMessage]="noDataMessage" [headers]="headersToShow" [columns]="columnsToShowForMultiSelect" [requiredMsg]="requiredMsg" [chipFieldToShow]="'MEMBER_NAME'"
      [removedFilterKey]="removedFilterKey">
    </app-multi-select-list>
 
    <app-multi-select-list *ngIf="requiredFilters?.multiSelectHierarchyMemberFilter"
      (updateDealerSearch)="updateSearchInfo($event)" (updateSelectedDealers)="updateFilterSeletedDealers($event)"
      (clearFilter)="clearFilterKey($event)" [multiSelectFilterTitle]="'HIERARCHY MEMBER:*'"
      [listData]="hierarchyResp" [isDropDownDisbaled]="isMultiSelectDisabled" [multiSelectDropdownType]="'dealer'" [requiredMsg]="requiredMsg"
       [headers]="headersToShow" [noDataValidationMessage]="noDataMessage" [columns]="columnsToShowForMultiSelect" [chipFieldToShow]="'MEMBER_NAME'"
      [removedFilterKey]="removedFilterKey">
    </app-multi-select-list>
 
 
    <div class="right-btn">
      <button class="primary-button small-button" (click)='fetchResult()'>Search</button>
    </div>
  </ng-container>
 
 </div>
</aside>
 