<div *ngIf="partsServiceSalesAnalysisjsonObj?.datasource">
  <div class="dxfw-table-heading" *ngIf="partsServiceSalesAnalysisjsonObj?.datasource?.length > 0">
    {{reportTitleToDisplay}}</div>
  <div [ngClass]="partsServiceSalesAnalysisjsonObj?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
    <div *ngFor="let currdataSource of partsServiceSalesAnalysisjsonObj?.datasource; let k=index">
      <div class="dfxw-part-table" *ngFor="let datasource of currdataSource ; let  i = index ">
        <app-multi-header-table [tableIndex]="k" [multiHeaderTableData]='datasource' [reportType]="reportType">
        </app-multi-header-table>
      </div>
    </div>
  </div>
  <div class="dxfw-no-data"
    *ngIf="partsServiceSalesAnalysisjsonObj?.datasource?.length === 0 && selectedFilteredValues?.length != 0">
    {{noDataToDisplayMsg}}</div>
  <div class="dxfw-no-data" *ngIf="selectedFilteredValues?.length === 0">Please select the filters...</div>

</div>