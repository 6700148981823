import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { reportType } from 'src/app/constants/constants';
import { CRGridSearch } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';

@Component({
  selector: 'app-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrls: ['./filter-date-range.component.scss'],
})
export class FilterDateRangeComponent implements OnInit, OnDestroy {
  @Output() updateDateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter<string>();
  @Input() reportType;
  @Input() requiredMsg;
  @Input() noDataValidationMessage;
  selectOptions: any;
  subscription: Subscription = new Subscription();
  public hintLabel = '';
  public defaultSelected: string = '';
  private _dateFilteredData: string;

  get dateFilterData() {
    return this._dateFilteredData;
  }

  @Input() set dateFilterData(value: any) {
    this.defaultSelected = '';
    this._dateFilteredData = value;
  }

  @Input() set removedFilterKey(value: any) {
    if (value?.key === 'monthYear' || value?.key === 'company') {
      this.defaultSelected = '';
    } 
  }

 
  constructor(
  ) {
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  public setDefultFilterValues(searchKey, val): void {
    let formattedDate = new Date(val).toLocaleDateString('en-us', { year: "numeric", month: "long" })
    const searchChips = { key: searchKey, value: formattedDate };
    this.updateDateSearch.emit(searchChips);
    this.hintLabel = 'Hit on search,To fetch latest result!';
  }

  onClear() {
    this.defaultSelected = '';
    this.clearFilter.emit('monthYear');
  }

}
